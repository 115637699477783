import { useEffect, useState } from 'react';

function isRunningAsIOSApp(): boolean {
  return window.running_as_ios_app === true;
}

export function useIsRunningAsIOSApp() {
  const [isRunningAsIOSAppState, setIsRunningAsIOSApp] = useState(false);

  useEffect(() => {
    const poller = setInterval(() => {
      const iraia = isRunningAsIOSApp();
      setIsRunningAsIOSApp(iraia);
      if (iraia) {
        // Once the app is running in the container, this is probably not changing later.
        clearInterval(poller);
      }
    }, 200);
    setTimeout(() => {
      clearInterval(poller);
    }, 5000); // abort after 5 seconds

    return () => clearInterval(poller);
  }, []);

  return [isRunningAsIOSAppState];
}
